import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import './privacy-policy.css'

const PrivacyPolicy = (props) => {
  return (
    <div className="privacy-policy-container1">
      <Helmet>
        <title>Privacy Policy - DataFlows</title>
        <meta
          property="og:title"
          content="Privacy Policy - DataFlows"
        />
      </Helmet>
      <section className="privacy-policy-hero">
        <div className="privacy-policy-header-simple">
          <Link to="/" className="privacy-policy-logo-link">
            <img
              alt="DataFlows Logo"
              src="/dataflows-high-resolution-logo-200h.png"
              className="privacy-policy-logo"
            />
          </Link>
        </div>
        <div className="privacy-policy-hero-content">
          <div className="privacy-policy-content-container">
            <div className="privacy-policy-text-container1">
              <h1 className="privacy-policy-text05">Privacy Policy</h1>
              <span className="privacy-policy-text06">
                Last Updated: October 30, 2024
              </span>
              <h2 className="privacy-policy-text07">1. Introduction</h2>
              <span className="privacy-policy-text08">
                Welcome to DataFlows. We respect your privacy and are committed to protecting your personal data. This privacy policy will inform you about how we look after your personal data when you visit our website and tell you about your privacy rights and how the law protects you.
              </span>
              <h2 className="privacy-policy-text09">2. Data We Collect</h2>
              <span className="privacy-policy-text10">
                We may collect, use, store and transfer different kinds of personal data about you including:
                <br /><br />
                • Identity Data: includes first name, last name, username or similar identifier.
                <br />
                • Contact Data: includes email address and telephone numbers.
                <br />
                • Technical Data: includes internet protocol (IP) address, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website.
                <br />
                • Usage Data: includes information about how you use our website and services.
              </span>
              <h2 className="privacy-policy-text11">3. How We Use Your Data</h2>
              <span className="privacy-policy-text12">
                We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:
                <br /><br />
                • To process and deliver the services you have requested.
                <br />
                • To manage our relationship with you.
                <br />
                • To improve our website, products/services, marketing, and customer relationships.
                <br />
                • To recommend products or services that may be of interest to you.
              </span>
              <h2 className="privacy-policy-text13">4. Data Security</h2>
              <span className="privacy-policy-text14">
                We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used, or accessed in an unauthorized way, altered, or disclosed. We also limit access to your personal data to those employees, agents, contractors, and other third parties who have a business need to know.
              </span>
              <h2 className="privacy-policy-text15">5. Data Retention</h2>
              <span className="privacy-policy-text16">
                We will only retain your personal data for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.
              </span>
              <h2 className="privacy-policy-text17">6. Your Legal Rights</h2>
              <span className="privacy-policy-text18">
                Under certain circumstances, you have rights under data protection laws in relation to your personal data, including the right to:
                <br /><br />
                • Request access to your personal data.
                <br />
                • Request correction of your personal data.
                <br />
                • Request erasure of your personal data.
                <br />
                • Object to processing of your personal data.
                <br />
                • Request restriction of processing your personal data.
                <br />
                • Request transfer of your personal data.
                <br />
                • Right to withdraw consent.
              </span>
              <h2 className="privacy-policy-text19">7. Contact Us</h2>
              <span className="privacy-policy-text20">
                If you have any questions about this privacy policy or our privacy practices, please contact us at:
                <br /><br />
                Email: privacy@dataflows.com
                <br />
                Phone: +61 2 8046 6553
                <br />
                Address: Level 2, 11 York Street, Sydney NSW 2000, Australia
              </span>
            </div>
          </div>
        </div>
      </section>
      <footer className="privacy-policy-footer">
        <div className="privacy-policy-container2">
          <div className="privacy-policy-row">
            <div className="privacy-policy-column">
              <span className="privacy-policy-column-header">Solutions</span>
              <div className="privacy-policy-column-links">
                <span className="privacy-policy-link">Enterprise SMS</span>
                <span className="privacy-policy-link">Marketing SMS</span>
                <span className="privacy-policy-link">Authentication</span>
                <span className="privacy-policy-link">Appointments</span>
                <span className="privacy-policy-link">Surveys</span>
              </div>
            </div>
            <div className="privacy-policy-column">
              <span className="privacy-policy-column-header">Company</span>
              <div className="privacy-policy-column-links">
                <span className="privacy-policy-link">About</span>
                <span className="privacy-policy-link">Reseller</span>
                <Link to="/terms-of-use" className="privacy-policy-navlink2">
                  Terms of use
                </Link>
                <Link to="/privacy-policy" className="privacy-policy-navlink3">
                  Privacy Policy
                </Link>
              </div>
            </div>
          </div>
          <div className="privacy-policy-bottom">
            <img
              alt="DataFlows Logo"
              src="/dataflows-high-resolution-logo-200h.png"
              className="privacy-policy-branding"
            />
            <span className="privacy-policy-text30">
              Copyright © DataFlows - 2024
            </span>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default PrivacyPolicy 