import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import YouTube from '../components/you-tube'
import ContactForm41 from '../components/contact-form41'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container10">
      <Helmet>
        <title>DataFlows SMS Australia</title>
        <meta
          name="description"
          content="Australia's most affordable SMS solution to grow your business. Boost customer engagement with bulk SMS at unbeatable prices and exceptional service."
        />
        <meta property="og:title" content="DataFlows SMS Australia" />
        <meta
          property="og:description"
          content="Australia's most affordable SMS solution to grow your business. Boost customer engagement with bulk SMS at unbeatable prices and exceptional service."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/025c2770-6e43-436d-9f18-d13058d507c7/9268e657-66d0-44a1-bead-d528d7635e01?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <section className="home-hero">
        <header className="home-header">
          <div id="mobile-menu" className="home-mobile-navigation">
            <img
              alt="pastedImage"
              src="/pastedimage-no9b-1500h.png"
              className="home-logo1"
            />
            <div className="home-links1">
              <span className="Link">Solutions</span>
              <span className="Link">How it works</span>
              <span className="Link">Prices</span>
            </div>
            <div id="close-mobile-menu" className="home-close-mobile-menu">
              <svg viewBox="0 0 804.5714285714286 1024" className="home-icon10">
                <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
              </svg>
            </div>
          </div>
          <div className="home-desktop-navigation">
            <nav className="home-centered">
              <div className="home-left1">
                <Link to="/" className="home-navlink1">
                  <img
                    alt="pastedImage"
                    src="/dataflows-high-resolution-logo-200h.png"
                    className="home-logo2"
                  />
                </Link>
                <div className="home-links2">
                  <a href="#features" className="home-link10 Link">
                    Features
                  </a>
                  <a href="#how it works" className="home-link11 Link">
                    How it works
                  </a>
                  <a href="#pricing" className="home-link12 Link">
                    Prices
                  </a>
                </div>
              </div>
              <div className="home-right1">
                <a
                  href="https://sms.dataflows.com.au"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-sign-in Link"
                >
                  Sign in
                </a>
                <div className="home-get-started10">
                  <a
                    href="https://sms.dataflows.com.au/register"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link13"
                  >
                    Get started
                  </a>
                </div>
                <div id="open-mobile-menu" className="home-burger-menu">
                  <img
                    alt="pastedImage"
                    src="/pastedimage-yxbd.svg"
                    className="home-mobile-menu-button"
                  />
                </div>
              </div>
            </nav>
          </div>
          <div>
            <div className="home-container12">
              <Script
                html={`<script defer>
/*
Mobile menu - Code Embed
*/

// Mobile menu
const mobileMenu = document.querySelector("#mobile-menu")

// Buttons
const closeButton = document.querySelector("#close-mobile-menu")
const openButton = document.querySelector("#open-mobile-menu")

// On openButton click, set the mobileMenu position left to -100vw
openButton.addEventListener("click", function() {
    mobileMenu.style.transform = "translateX(0%)"
})

// On closeButton click, set the mobileMenu position to 0vw
closeButton.addEventListener("click", function() {
    mobileMenu.style.transform = "translateX(100%)"
})
</script>`}
              ></Script>
            </div>
          </div>
        </header>
        <header className="home-content">
          <h1 className="home-text103">
            Australia&apos;s Most Affordable SMS Solution to Scale Your Business
          </h1>
          <p className="home-text104">
            Communicate effortlessly, whether you're big or small
          </p>
          <div className="home-get-started11">
            <a
              href="https://sms.dataflows.com.au/register"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link14"
            >
              Get started
            </a>
          </div>
        </header>
        <div className="home-dashboard-preview">
          <div className="home-outline">
            <img
              alt="pastedImage"
              src="/logos/4-1400w.png"
              loading="lazy"
              className="home-image1"
            />
          </div>
        </div>
      </section>
      <section id="features" className="home-features">
        <div className="home-title">
          <span className="home-text105">Effortless Messaging</span>
          <span className="home-text106">
            Our easy-to-use platforms and intuitive interfaces make sending and
            receiving messages a breeze. Expert service, unbeatable
            prices—messaging made simple.
          </span>
        </div>
        <div className="home-cards1">
          <div className="home-container13">
            <div className="card">
              <img
                alt="pastedImage"
                src="/pastedimage-fii6m-200h.png"
                className="home-icon12 hover-pulse"
              />
              <span className="home-text107">Bulk SMS</span>
              <span className="home-text108">
                <br></br>
                <span>
                  Deliver messages to large audiences quickly and efficiently.
                </span>
              </span>
            </div>
            <div className="home-publish1 card">
              <img
                alt="pastedImage"
                src="/pastedimage-mimg-200h.png"
                className="home-icon13 hover-pulse"
              />
              <span className="home-text111">SMS API</span>
              <span className="home-text112">
                <br></br>
                <span>
                  Integrate our SMS service with your existing systems.
                </span>
              </span>
            </div>
          </div>
          <div className="home-container14">
            <div className="card home-analyze1">
              <img
                alt="pastedImage"
                src="/pastedimage-l6p-200h.png"
                className="home-icon14 hover-pulse"
              />
              <span className="home-text115">Branded SMS</span>
              <span className="home-text116">
                Send messages using your business name instead of a generic
                number, ensuring your brand is recognized immediately by your
                customers.
              </span>
            </div>
            <div className="card">
              <img
                alt="pastedImage"
                src="/pastedimage-vyi5-200h.png"
                className="home-icon15 hover-pulse"
              />
              <span className="home-text117">Two-Way Messaging</span>
              <span className="home-text118">
                <br></br>
                <span>
                  Engage in meaningful conversations with your customers.
                </span>
              </span>
            </div>
          </div>
          <div className="home-container15">
            <div className="card">
              <img
                alt="pastedImage"
                src="/pastedimage-fii6m-200h.png"
                className="home-icon16 hover-pulse"
              />
              <span className="home-text121">OTP (One-Time Password)</span>
              <span className="home-text122">
                <br></br>
                <span>
                  Enhance security with our OTP service. Send one-time passwords
                  for seamless verification.
                </span>
              </span>
            </div>
            <div className="home-publish2 card">
              <img
                alt="pastedImage"
                src="/pastedimage-mimg-200h.png"
                className="home-icon17 hover-pulse"
              />
              <span className="home-text125">Automation</span>
              <span className="home-text126">
                <br></br>
                <span>
                  Automate repetitive tasks like birthday wishes and reminders.
                  Keep your audience engaged effortlessly.
                </span>
                <br></br>
              </span>
            </div>
          </div>
          <div className="home-container16">
            <div className="card">
              <img
                alt="pastedImage"
                src="/pastedimage-fii6m-200h.png"
                className="home-icon18 hover-pulse"
              />
              <span className="home-text130">Dashboard</span>
              <span className="home-text131">
                <br></br>
                <span>
                  Manage everything from one intuitive dashboard. Keep an eye on
                  all your messaging activities effortlessly.
                </span>
              </span>
            </div>
            <div className="home-publish3 card">
              <img
                alt="pastedImage"
                src="/pastedimage-mimg-200h.png"
                className="home-icon19 hover-pulse"
              />
              <span className="home-text134">Campaigns</span>
              <span className="home-text135">
                <span>
                  Launch targeted campaigns with precision. Create, execute, and
                  monitor your marketing initiatives seamlessly.
                </span>
                <br></br>
              </span>
            </div>
          </div>
          <div className="home-container17">
            <div className="card home-analyze2">
              <img
                alt="pastedImage"
                src="/pastedimage-l6p-200h.png"
                className="home-icon20 hover-pulse"
              />
              <span className="home-text138">Blacklist</span>
              <span className="home-text139">
                <br></br>
                <span>
                  Block unwanted numbers easily. Ensure your messages reach the
                  right people.
                </span>
              </span>
            </div>
            <div className="card">
              <img
                alt="pastedImage"
                src="/pastedimage-vyi5-200h.png"
                className="home-icon21 hover-pulse"
              />
              <span className="home-text142">Analysis</span>
              <span className="home-text143">
                <br></br>
                <span>
                  Gain insights with our analysis tools. Understand your
                  audience and refine your strategies effectively.
                </span>
              </span>
            </div>
          </div>
          <div className="home-container18">
            <div className="card home-analyze3">
              <img
                alt="pastedImage"
                src="/pastedimage-l6p-200h.png"
                className="home-icon22 hover-pulse"
              />
              <span className="home-text146">
                <span>Templates</span>
                <br></br>
              </span>
              <span className="home-text149">
                <span>
                  Design reusable templates for various messaging needs. From
                  reminders to promotional messages, we&apos;ve got you covered.
                </span>
                <br></br>
              </span>
            </div>
            <div className="card">
              <img
                alt="pastedImage"
                src="/pastedimage-vyi5-200h.png"
                className="home-icon23 hover-pulse"
              />
              <span className="home-text152">Schedule Campaigns</span>
              <span className="home-text153">
                <br></br>
                <span>
                  Plan and schedule your campaigns ahead of time. Reach your
                  audience at the optimal moment.
                </span>
                <br></br>
              </span>
            </div>
          </div>
        </div>
      </section>
      <section className="home-quote-container">
        <div className="home-quote1">
          <span className="home-message">
            &quot;Our SMS campaigns have transformed our customer
            engagement—simple, effective, and reliable. Sending messages with
            our brand name has boosted our credibility and customer trust.&quot;
          </span>
          <div className="home-author">
            <img
              alt="image"
              src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDV8fGdpcmx8ZW58MHx8fHwxNjY0ODA5MjE1&amp;ixlib=rb-1.2.1&amp;w=200"
              className="home-avatar"
            />
            <span className="home-quote2">
              <span className="home-text157">—  Natalie Furrow</span>
              <span>, Founder and CEO</span>
            </span>
          </div>
        </div>
      </section>
      <section className="home-banners">
        <div className="home-banner-manage">
          <div className="home-container19">
            <div className="home-left2">
              <span className="sub-title">Content Management</span>
              <span className="home-text160 title">
                Reach all your customers in just one place.
              </span>
              <span className="home-text161">
                <span className="home-text162">
                  Manage all your customer communications effortlessly from a
                  single platform. Whether you&apos;re sending promotions,
                  reminders, or updates, our service allows you to reach your
                  entire audience in one streamlined place, saving you time and
                  ensuring consistent messaging.
                </span>
                <br></br>
                <br></br>
              </span>
              <div className="home-get-started12 template-button">
                <a
                  href="https://sms.dataflows.com.au/register"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link15"
                >
                  Get started
                </a>
              </div>
            </div>
            <div className="home-image-container1">
              <img
                alt="pastedImage"
                src="/logos/brand%20loyalty-pana-1200w.png"
                className="home-cards-image1 animate-pulse"
              />
            </div>
          </div>
        </div>
        <div className="home-banner-advanced-analytics1">
          <div className="home-centered-container1">
            <div className="home-image-container2">
              <img
                alt="pastedImage"
                src="/data%20report-pana-1200w.png"
                className="home-cards-image2 animate-slideInLeft"
              />
            </div>
            <div className="home-right2">
              <span className="sub-title">Features</span>
              <h2 className="home-text166 title">
                SMS Power Features: Scale, Track, and Personalise!
              </h2>
              <div className="home-category10">
                <span className="home-text167">Scalability</span>
                <span className="home-text168">
                  Handle large volumes of SMS with ease
                </span>
              </div>
              <div className="home-category11">
                <span className="home-text169">Delivery Reports</span>
                <span className="home-text170">
                  Track message delivery in real-time.
                </span>
              </div>
              <div className="home-category12">
                <span className="home-text171">
                  <br></br>
                  <span>Personalisation</span>
                </span>
                <span className="home-text174">
                  Customize messages for different customer segments
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="home-banner1">
          <div className="home-container20">
            <div className="home-left3">
              <span className="home-text175">Use Cases</span>
              <h2 className="home-text176 title">
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>Supercharge Your Sales with Precision SMS Campaigns</span>
              </h2>
              <span className="home-text179">
                <span>
                  Transform your marketing strategy with targeted SMS campaigns
                  designed to engage your audience and drive conversions. Our
                  platform allows you to segment your audience and craft
                  personalized messages that resonate with each group, ensuring
                  maximum impact and increased sales.
                </span>
                <br></br>
                <br></br>
              </span>
              <div className="home-get-started13 template-button">
                <a
                  href="https://sms.dataflows.com.au/register"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link16"
                >
                  Get started
                </a>
              </div>
            </div>
            <div className="home-image-container3">
              <img
                alt="pastedImage"
                src="/logos/messages-amico-1200w.png"
                className="home-cards-image3"
              />
            </div>
          </div>
        </div>
        <div className="home-banner-advanced-analytics2">
          <div className="home-centered-container2">
            <div className="home-image-container4">
              <img
                alt="pastedImage"
                src="/schedule-amico-1200w.png"
                className="home-cards-image4 animate-slideInRight"
              />
            </div>
            <div className="home-right3">
              <span className="sub-title">Use Cases</span>
              <h2 className="home-text184 title">
                {' '}
                Scheduled Messaging: Send When It Matters Most
              </h2>
              <div className="home-category13">
                <span className="home-text185">
                  Flexible Timing:
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="home-text186">
                  Set messages to go out on specific days and times that fit
                  your strategy.
                </span>
              </div>
              <div className="home-category14">
                <span className="home-text187">
                  Automated Campaigns:
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="home-text188">
                  Schedule SMS blasts in advance, ensuring consistent
                  communication without manual effort.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </div>
              <div className="home-category15">
                <span className="home-text189">
                  <br></br>
                  <span>
                    Targeted Delivery:
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </span>
                <span className="home-text192">
                  {' '}
                  Align your campaigns with customer time zones or peak
                  engagement periods for maximum impact.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="home-banner2">
          <div className="home-container21">
            <div className="home-left4">
              <span className="home-text193">Use Cases</span>
              <h2 className="home-text194 title">
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  Keep Your Customers Ahead with Instant SMS Alerts and Timely
                  Updates
                </span>
              </h2>
              <span className="home-text197">
                <span>
                  Keep your audience informed with real-time SMS alerts and
                  notifications. Whether it's important updates, critical
                  alerts, or timely information, our platform ensures your
                  messages reach the right people instantly, keeping them
                  engaged and informed.
                </span>
                <br></br>
                <br></br>
              </span>
              <div className="home-get-started14 template-button">
                <a
                  href="https://sms.dataflows.com.au/register"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link17"
                >
                  Get started
                </a>
              </div>
            </div>
            <div className="home-image-container5">
              <img
                alt="pastedImage"
                src="/mobile%20marketing-rafiki-1200w.png"
                className="home-cards-image5 animate-slideInBottom"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="home-integrations">
        <div className="home-centered-container3">
          <div className="home-heading1">
            <span className="sub-title">Integrations</span>
            <span className="title">
              Integrated with the tools you know and love
            </span>
            <span className="home-text203">
              <span className="home-text204">
                Easily synchronize with the tools you already use to streamline
                your workflow and maximize efficiency.
              </span>
              <br></br>
              <br></br>
              <br></br>
            </span>
          </div>
          <div className="home-pills-container">
            <div className="home-pills">
              <div className="home-container22">
                <YouTube pastedImageSrc="/logos/mailchimp-logo-700x394-2-200h.png"></YouTube>
                <YouTube pastedImageSrc="/lglogopowerautomate-200h.png"></YouTube>
                <YouTube pastedImageSrc="/oracle-512-200h.webp"></YouTube>
                <YouTube pastedImageSrc="/images-2-200h.png"></YouTube>
              </div>
              <div className="home-container23">
                <YouTube pastedImageSrc="/salesforce.svg"></YouTube>
                <YouTube pastedImageSrc="/shopify-2.svg"></YouTube>
                <YouTube pastedImageSrc="/zapier_official_logo_icon_167936-200h.png"></YouTube>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="how it works" className="home-how-it-works">
        <span className="home-text208">How it works</span>
        <span className="home-text209 title">
          Get Started in 3 Simple Steps
        </span>
        <div className="home-centered-container4">
          <div className="home-container24">
            <div className="home-heading2"></div>
          </div>
          <div className="home-category16">
            <div className="home-headng1">
              <span className="home-text210">
                1 — Sign up
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text211">
                <span>
                  Sign up to DataFlows. Create your account in minutes and get
                  ready to connect with your audience.
                </span>
                <br></br>
                <br></br>
              </span>
              <a
                href="https://sms.dataflows.com.au/register"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link18"
              >
                <div className="home-get-started15 template-button">
                  <span className="home-text215">Get started</span>
                </div>
              </a>
            </div>
            <div className="home-container25">
              <img
                alt="pastedImage"
                src="/sign%20up-amico-1200w.png"
                className="home-pasted-image1"
              />
            </div>
          </div>
          <div className="home-row">
            <div className="home-category17">
              <div className="home-headng2">
                <span className="home-text216">2 — Select Plan</span>
                <span className="home-text217">
                  <br></br>
                  <span>
                    Choose the perfect plan tailored to your messaging needs and
                    budget.
                  </span>
                </span>
              </div>
              <img
                alt="pastedImage"
                src="/mobile%20login-pana-1200w.png"
                className="home-pasted-image2"
              />
            </div>
            <div className="home-category18">
              <div className="home-headng3">
                <span className="home-text220">2 — Start SMS</span>
                <span className="home-text221">
                  <br></br>
                  <span>
                    Begin sending personalized messages and track your results
                    instantly.
                  </span>
                </span>
              </div>
              <img
                alt="pastedImage"
                src="/logos/done-rafiki-1200w.png"
                className="home-pasted-image3"
              />
            </div>
          </div>
        </div>
      </section>
      <section id="pricing" className="home-pricing">
        <div className="home-centered-container5">
          <div className="home-heading3">
            <span className="home-text224 title">
              Pricing for all kind of businesses
            </span>
            <span className="home-text225">
              Create next-generation solutions for small business customers with
              pricing options that accommodate everyone.
            </span>
            <div className="home-selection"></div>
          </div>
          <div className="home-cards2">
            <div className="home-card1">
              <img
                alt="image"
                src="/logos/3%20cents%20sms-4-200h.png"
                className="home-image2 animate-fadeIn delay-100"
              />
              <span className="home-text226">Mini</span>
              <span className="home-text227">$30 AUD</span>
              <a
                href="https://sms.dataflows.com.au/register"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link19"
              >
                <div className="home-get-started16 template-button">
                  <span className="home-text228">Select Plan</span>
                </div>
              </a>
              <span className="home-text229">What&apos;s included</span>
              <div className="home-bullet-points10">
                <div className="home-point10">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon24"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text230">
                    <br></br>
                    <span>
                       1000 SMS Count -
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <br></br>
                    <span>3 cents/SMS</span>
                  </span>
                </div>
              </div>
              <div className="home-bullet-points11">
                <div className="home-point11">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon26"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text235"> 30 Contact Lists</span>
                </div>
                <div className="home-point12">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon28"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text236">
                     Free Shared AU Number.  
                  </span>
                </div>
                <div className="home-point13">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon30"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text237"> 30 Contact Lists</span>
                </div>
              </div>
              <div className="home-bullet-points12">
                <div className="home-point14">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon32"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text238"> 30 SMS Templates</span>
                </div>
              </div>
              <div className="home-bullet-points13">
                <div className="home-point15">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon34"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text239"> Zero Subscription Fees</span>
                </div>
                <div className="home-point16">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon36"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text240"> 3 months Credit Expiry</span>
                </div>
              </div>
            </div>
            <div className="home-card2">
              <img
                alt="image"
                src="/logos/3%20cents%20sms-5-200h.png"
                className="home-image3 animate-fadeIn delay-200"
              />
              <span className="home-text241">Pro</span>
              <span className="home-text242">$150 AUD</span>
              <a
                href="https://sms.dataflows.com.au/register"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link20"
              >
                <div className="home-get-started17 template-button">
                  <span className="home-text243">Select Plan</span>
                </div>
              </a>
              <span className="home-text244">What&apos;s included</span>
              <div className="home-bullet-points14">
                <div className="home-point17">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon38"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text245">
                    <br></br>
                    <span>
                       5350 SMS Count -
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <br></br>
                    <span>2.8 cents/SMS</span>
                  </span>
                </div>
                <div className="home-point18">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon40"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text250">
                    <span> </span>
                    <br></br>
                    <span> Free Sender ID (Business Name)</span>
                  </span>
                </div>
                <div className="home-point19">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon42"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text254"> Free Shared AU Number</span>
                </div>
                <div className="home-point20">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon44"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text255"> 50 Contact Lists</span>
                </div>
              </div>
              <div className="home-bullet-points15">
                <div className="home-point21">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon46"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text256"> 50 SMS Templates</span>
                </div>
                <div className="home-point22">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon48"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text257"> API Integrations</span>
                </div>
                <div className="home-point23">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon50"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text258"> Zero Subscription Fees</span>
                </div>
                <div className="home-point24">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon52"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text259"> 6 months Credit Expiry</span>
                </div>
              </div>
            </div>
            <div className="home-card3">
              <img
                alt="image"
                src="/logos/3%20cents%20sms-6-200h.png"
                className="home-image4 animate-fadeIn delay-300"
              />
              <span className="home-text260">Business</span>
              <span className="home-text261">$500</span>
              <a
                href="https://sms.dataflows.com.au/register"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link21"
              >
                <div className="home-get-started18 template-button">
                  <span className="home-text262">
                    <span>Select Plan</span>
                    <br></br>
                  </span>
                </div>
              </a>
              <span className="home-text265">What&apos;s included</span>
              <div className="home-bullet-points16">
                <div className="home-point25">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon54"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text266">
                    <br></br>
                    <span>
                       20000 SMS Count -
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <br></br>
                    <span>2.5 cents/SMS</span>
                  </span>
                </div>
                <div className="home-point26">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon56"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text271">
                    <span> </span>
                    <br></br>
                    <span> Free Sender ID (Business Name)</span>
                  </span>
                </div>
                <div className="home-point27">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon58"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text275">
                     Free Dedicated AU Number
                  </span>
                </div>
                <div className="home-point28">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon60"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text276"> Unlimited Contact Lists</span>
                </div>
              </div>
              <div className="home-bullet-points17">
                <div className="home-point29">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon62"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text277"> Unlimited SMS Templates</span>
                </div>
                <div className="home-point30">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon64"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text278"> API Integrations</span>
                </div>
                <div className="home-point31">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon66"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text279"> Zero Subscription Fees</span>
                </div>
              </div>
              <div className="home-bullet-points18">
                <div className="home-point32">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon68"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text280"> 1 Year Credit Expiry</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-card4">
          <span className="home-text281">Enterprise</span>
          <span className="home-text282">Contact Us For Price</span>
          <a href="#ContactForm41" className="home-link22">
            <div className="home-get-started19 template-button">
              <span className="home-text283">
                <span>Contact us</span>
                <br></br>
              </span>
            </div>
          </a>
          <span className="home-text286">What&apos;s included</span>
          <div className="home-bullet-points19">
            <div className="home-point33">
              <svg viewBox="0 0 877.7142857142857 1024" className="home-icon70">
                <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
              </svg>
              <span className="home-text287">
                All Features at an Unbeatable Price for High-Volume Sending.
              </span>
            </div>
          </div>
        </div>
      </section>
      <section className="home-action-bar">
        <div className="home-action">
          <div className="home-heading4">
            <span className="home-text288 title">
              Boost Your Reach with DataFlows!
            </span>
            <span className="home-text289">
              <span>
                Leverage the power of data to connect with your audience like
                never before.
              </span>
              <br></br>
              <br></br>
            </span>
            <a
              href="https://dataflows.com.au/register"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link23"
            >
              <div className="home-get-started20 template-button">
                <span className="home-text293">
                  <span>Get Started</span>
                  <br></br>
                </span>
              </div>
            </a>
          </div>
          <div className="home-images">
            <img
              alt="image"
              src="/launching-amico-1200w.png"
              className="home-image5 animate-bounce"
            />
          </div>
        </div>
      </section>
      <ContactForm41
        action={
          <Fragment>
            <span className="home-text296 thq-body-small">Submit</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text297 thq-body-small">
              <span>
                Have a question or need more information? Fill out the form
                below, and we will get back to you as soon as possible.
              </span>
              <span> </span>
            </span>
          </Fragment>
        }
        content2={
          <Fragment>
            <span className="home-text300 thq-body-small">
              Get in touch with us
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text301 thq-heading-2">Contact us</span>
          </Fragment>
        }
        rootClassName="contact-form41root-class-name"
        contactForm41Id="ContactForm41"
      ></ContactForm41>
      <footer className="home-footer">
        <div className="home-top">
          <div className="home-left5">
            <div className="home-contact">
              <span className="home-text302">Contact</span>
              <a
                href="mailto:use@active-app.com?subject=Support"
                className="home-link24"
              >
                sales@dataflows.com.au
              </a>
            </div>
          </div>
          <div className="home-right4">
            <div className="home-category19">
              <span className="home-text303">Solutions</span>
              <div className="home-links3">
                <span className="home-text304">SMPP Solutions</span>
                <span className="home-text305">SMS API</span>
                <span className="home-text306">Complex Integrations</span>
                <span className="home-text307">Encrypted Messaging</span>
                <span className="home-text308">Automation</span>
              </div>
            </div>
            <div className="home-category20">
              <span className="home-text309">Company</span>
              <div className="home-links4">
                <span className="home-text310">About</span>
                <span className="home-text311">Reseller</span>
                <Link to="/terms-of-use" className="home-navlink2">
                  Terms of use
                </Link>
                <Link to="/privacy-policy" className="home-navlink3">
                  Privacy Policy
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="home-bottom">
          <img
            alt="pastedImage"
            src="/dataflows-high-resolution-logo-200h.png"
            className="home-branding"
          />
          <span className="home-text313">Copyright © DataFlows - 2024</span>
        </div>
      </footer>
    </div>
  )
}

export default Home
